import React from "react"
import PropTypes from "prop-types"
import dateFormat from "dateformat"

const CookingNote = props => (
  <li className="col-md-4 pl-0 pr-5">
    <span className="d-block pb-1 text-muted">
      <span className="d-inline-block">
        {dateFormat(props.date, "dd/mm/yy")}
      </span>
      <span className="d-inline-block pl-4">{props.score}</span>
    </span>
    {props.comment}
  </li>
)

CookingNote.propTypes = {
  comment: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired,
}

export default CookingNote
