import React from "react"
import PropTypes from "prop-types"

function toggleClass(e) {
  e.preventDefault()
  let target = e.target
  target.classList.toggle("text-secondary")
}

const Ingredient = props => (
  <li className="mb-1">
    <button className="text-left border-0 bg-transparent" onClick={toggleClass}>
      {props.what}
    </button>
  </li>
)

Ingredient.propTypes = {
  what: PropTypes.string.isRequired,
}

export default Ingredient
