import React from "react"
import { graphql } from "gatsby"
import dateFormat from "dateformat"

import Image from "../components/shared/image"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"

import CookingNote from "../components/recipe/cookingNote"
import Ingredient from "../components/recipe/ingredient"
import Instruction from "../components/recipe/instruction"
import Source from "../components/recipe/source"

export default function Recipe({ data }) {
  const recipe = data.recipesJson
  let cookingNotes = null

  let ingredients = recipe.ingredients.map((ingredient, index) => {
    return <Ingredient what={ingredient.what} key={index} />
  })

  let instructions = recipe.instructions.map((instruction, index) => {
    return <Instruction details={instruction.details} key={index} />
  })

  if (recipe.cookingNotes !== null && recipe.cookingNotes.length > 0) {
    cookingNotes = recipe.cookingNotes.reverse().map((note, index) => {
      return (
        <CookingNote
          comment={note.comment}
          date={note.date}
          score={note.score}
          key={index}
        />
      )
    })
  }

  return (
    <Layout>
      <div className="e_recipe">
        <SEO title={recipe.name} />
        <div className="position-relative" style={{ minHeight: "100px" }}>
          <Image src={recipe.recipeImage} />
          <div className="e_recipe__header position-absolute d-flex justify-content-center align-items-center text-center px-5">
            <h1 className="text-white text-uppercase mb-0">{recipe.name}</h1>
          </div>
        </div>
        <div className="container mt-4 mt-md-5">
          <div className="d-flex justify-content-between flex-wrap">
            <div className="helper-w-mob-33 mb-md-0">
              <span className="h6">Cuisine</span>
              <br />
              <span>{recipe.cuisineType}</span>
            </div>
            <div className="helper-w-mob-33 mb-4 mb-md-0">
              <span className="h6">Type</span>
              <br />
              <span>{recipe.recipeType}</span>
            </div>
            <div className="helper-w-mob-33 mb-4 mb-md-0">
              <span className="h6">Source</span>
              <br />
              <span>
                <Source source={recipe.source} />
              </span>
            </div>
            <div className="helper-w-mob-33 mb-4 mb-md-0">
              <span className="h6">Serves</span>
              <br />
              <span>{recipe.serves}</span>
            </div>
            <div className="helper-w-mob-33 mb-4 mb-md-0">
              <span className="h6">Time</span>
              <br />
              <span>{recipe.cookingTime}</span>
            </div>
            <div className="helper-w-mob-33 mb-4 mb-md-0">
              <span className="h6">Last cooked</span>
              <br />
              <span>
                {recipe.lastCooked !== null
                  ? dateFormat(recipe.lastCooked, "dd/mm/yy")
                  : "Never"}
              </span>
            </div>
          </div>
        </div>
        {recipe.note !== null && recipe.note.length !== 0 && (
          <div className="container mt-md-4 mt-md-5">
            <div className="row">
              <div className="col-md-12 font-italic text-center">
                "{recipe.note}"
              </div>
            </div>
          </div>
        )}
        <div className="container mt-4 mt-md-5">
          <div className="row">
            <div className="col-md-4 mb-3 mb-md-0">
              <h2>Ingredients</h2>
              <ul className="pl-3">{ingredients}</ul>
            </div>
            <div className="col-md-8 mb-3 mb-md-0">
              <h2>Instructions</h2>
              <ol className="pl-3">{instructions}</ol>
            </div>
          </div>
        </div>
        {recipe.cookingNotes !== null && recipe.cookingNotes.length > 0 && (
          <div className="container mt-md-4 mt-md-5">
            <div className="row">
              <div className="col-md-12">
                <h2>Cooking Notes</h2>
                <div className="e_recipe__cooking-notes container">
                  <ul className="row p-0">{cookingNotes}</ul>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    recipesJson(fields: { slug: { eq: $slug } }) {
      cookingNotes {
        comment
        date
        score
      }
      cookingTime
      cuisineType
      ingredients {
        what
      }
      instructions {
        details
      }
      lastCooked
      name
      note
      recipeImage
      recipeType
      serves
      source
    }
  }
`
