import React from "react"
import PropTypes from "prop-types"

const Source = props => {
  function getSource() {
    if (props.source.startsWith("http")) {
      let linkText = props.source
        .replace(/http(s)?:\/\//, "")
        .replace(/www./, "")
      linkText = linkText.substring(0, linkText.indexOf("/"))
      return {
        __html:
          '<a href="' + props.source + '" target="_blank">' + linkText + "</a>",
      }
    } else {
      return { __html: props.source }
    }
  }

  return <span dangerouslySetInnerHTML={getSource()} />
}

Source.propTypes = {
  source: PropTypes.string.isRequired,
}

export default Source
